import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FadeInSection from "./FadeInSection";

const Vision = () => {
    const [isShown, setIsShown] = useState(true);
    return(
        <div id="vision" className="my-5 pt-5">
            <Container className="text-black">
                <div className="component-title mb-2 text-center text-uppercase font-weight-bold">Vision</div>
                <div className="component-desc text-center text-black font-weight-bold mb-3 px-lg-5">Make the Agri-Input retail market as  transparent as grocery retail</div>
                {isShown && (
                    <FadeInSection>
                        <Row className="pb-4 pt-md-4 my-3 align-items-center">
                            <Col lg={5} md={6} xs={12} className="mr-lg-4 order-md-1 order-2">
                                <div className="bg-titan text-center py-3 px-2 oval-radius">
                                    <img src="images/vision.svg" height="330" alt="vision" className="w-100" />
                                </div>
                            </Col>
                            <Col md={6} xs={12} className="mb-md-0 mb-3 order-md-2 order-1">
                                <ul className="data-list mt-3">
                                    <li className="mb-4 pl-3 px-4">Lack of information rather than resources is the major problem in the Indian agriculture input value chain. With growing internet users in rural India, we feel it is time to develop innovative solutions to help farmers reduce the costs of buying agriculture inputs.</li>
                                </ul>
                            </Col>
                        </Row>
                    </FadeInSection>
                 )}
                {/*TODO: not for production */}
                {/* <div className="mt-5 vision-count d-flex p-5">
                    <div className="d-flex flex-column col text-center">
                        <h4 className="text-black font-weight-light">Farmer Download</h4>
                        <h2 className="text-teal m-0">60,000+</h2>
                    </div>
                    <div className="d-flex flex-column col text-center">
                        <h4 className="text-black font-weight-light">GMV</h4>
                        <h2 className="text-teal m-0">35 Lakhs</h2>
                    </div>
                </div> */}
            </Container>
        </div>
    )
}

export default Vision