import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import firebase from "firebase"

const ContactUs = () => {

    const [formValues, setFormValues] = useState({ name: "", contact: "", message: "", email: "" });
    const [fieldError, SetFieldError] = useState({ name: "", contact: "", message: "", email: "" });

    const handleFieldOnChange = (event: any) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const validateForm = (values: any) => {
        let err = {
            name: '',
            contact: '',
            message: '',
            email: ''
        };
        if (!values.name) {
            err.name = "Please enter your name";
        } else if (!values.contact) {
            err.contact = "Please enter a valid contact number"
        } else if (!values.message) {
            err.message = "Please enter a message"
        }

        return err;
    }

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        var errors = validateForm(formValues)
        SetFieldError(errors);
        if (errors.name.length > 0 || errors.message.length > 0 || errors.contact.length > 0) {
            return
        } else {
            const doc_ref = firebase.firestore().collection("website-lead").doc()

            await doc_ref.set({
                ...formValues,
                id: doc_ref.id
            })

            setFormValues({ name: "", contact: "", message: "", email: "" })
        }
    }

    return (
        <div id="contact">
            <Container className="mb-5 mt-md-4 text-black">
                <div className="component-title mb-3 text-center text-uppercase font-weight-bold">CONTACT US</div>
                <Form noValidate onSubmit={handleFormSubmit}>
                    <Row>
                        <Col md={6} xs={12}>
                            <Form.Group>
                                <div className={(fieldError.name ? 'border border-danger ' : 'form-input-border ') + " position-relative rounded d-flex mb-3"}>
                                    <img src="images/user.svg" width="16" alt="user" className="icon-controls ml-2" />
                                    <Form.Control type="text" name="name" onChange={handleFieldOnChange} value={formValues.name} placeholder="Your Name" className="border-0 no-outline" />
                                    <span className="text-danger ml-auto mr-2">*</span>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className={(fieldError.contact ? 'border border-danger ' : 'form-input-border ') + " position-relative rounded d-flex mb-3"}>
                                    <img src="images/call.svg" width="16" alt="call" className="icon-controls ml-2" />
                                    <Form.Control type="number" name="contact" onChange={handleFieldOnChange} value={formValues.contact} placeholder="Contact Number" className="border-0 no-outline" />
                                    <span className="text-danger ml-auto mr-2">*</span>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className="position-relative form-input-border rounded d-flex mb-3">
                                    <img src="images/email.svg" width="16" alt="email" className="icon-controls ml-2" />
                                    <Form.Control type="email" name="email" onChange={handleFieldOnChange} value={formValues.email} placeholder="E-Mail ID" className="border-0 no-outline" />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                            <div className="position-relative mb-3">
                                <Form.Control type="text" as="textarea" name="message" onChange={handleFieldOnChange} value={formValues.message} placeholder="Your Message" className={(fieldError.message ? 'border border-danger ' : 'form-input-border ') + " form-textarea no-outline overflow-auto pr-3"} />
                                <span className="text-danger ml-auto mr-2 position-absolute estrick-area">*</span>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex">
                        <Button variant="primary" type="submit" className="ml-md-auto bg-teal font-weight-bold form-submit-btn no-outline">Send</Button>
                    </div>
                </Form>
            </Container>
        </div>
    )
}

export default ContactUs