import React, { useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Media from "react-media";
import { HashLink } from "react-router-hash-link";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { OnlyLogo } from "./LogoHeader";



const Header = () => {

    const [isHeaderMblMenuOpen, setIsHeaderMblMenuOpen] = useState(false);

    return (
        <header className="bg-white py-3 sticky-top box-shadow border-bottom main-header">
            <Container>
                <Row className="m-0">
                    <div>
                        <OnlyLogo />
                    </div>

                    <Media query="(min-width: 992px)">
                        {matches => 
                            matches ? (
                                <div className="d-flex ml-auto align-items-center">
                                    <HashLink to="/#vision" scroll={(el) => el.scrollIntoView({ block:'nearest', behavior: "smooth", inline: "start"  })} className="py-2 px-3 header-link text-gray-light no-underline">Vision</HashLink>
                                    <HashLink to="/#solution" scroll={(el) => el.scrollIntoView({ block:'nearest', behavior: "smooth", inline: "start"  })} className="py-2 px-3 header-link text-gray-light no-underline">Solution</HashLink>
                                    <HashLink to="/#testimonial" scroll={(el) => el.scrollIntoView({ block:'nearest', behavior: "smooth", inline: "start"  })} className="py-2 px-3 header-link text-gray-light no-underline">Testimonial</HashLink>
                                    <HashLink to="/#team" scroll={(el) => el.scrollIntoView({ block:'nearest', behavior: "smooth", inline: "start"  })} className="py-2 px-3 header-link text-gray-light no-underline">Our Team</HashLink>
                                    <HashLink to="/#contact" scroll={(el) => el.scrollIntoView({ block:'nearest', behavior: "smooth", inline: "start"  })} className="py-2 px-3 header-link text-gray-light no-underline">Contact Us</HashLink>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <Button variant="link" className="px-0 no-outline ml-auto" onClick={() => setIsHeaderMblMenuOpen(!isHeaderMblMenuOpen)}>
                                        <img src="images/burger-icon.svg" width="30" alt="burger" className="" />
                                    </Button>
                                    <SlidingPane
                                        className="mbl-header bg-white px-3"
                                        isOpen={isHeaderMblMenuOpen}
                                        onRequestClose={() => {
                                        setIsHeaderMblMenuOpen(!isHeaderMblMenuOpen);
                                        }}
                                    >
                                        <HashLink to="/#vision" scroll={(el) => { el.scrollIntoView({ block:'nearest', inline: "start"  }); setIsHeaderMblMenuOpen(!isHeaderMblMenuOpen)}} className="p-3 header-link text-black no-underline d-block border-bottom">Vision</HashLink>
                                        <HashLink to="/#solution" scroll={(el) => { el.scrollIntoView({ block:'nearest', inline: "start" }); setIsHeaderMblMenuOpen(!isHeaderMblMenuOpen)}} className="p-3 header-link text-black no-underline d-block border-bottom">Solution</HashLink>
                                        <HashLink to="/#testimonial" scroll={(el) => { el.scrollIntoView({ block:'nearest', inline: "start" }); setIsHeaderMblMenuOpen(!isHeaderMblMenuOpen)}} className="p-3 header-link text-black no-underline d-block border-bottom">Testimonial</HashLink>
                                        <HashLink to="/#team" scroll={(el) => { el.scrollIntoView({ block:'nearest', inline: "start"  }); setIsHeaderMblMenuOpen(!isHeaderMblMenuOpen)}} className="p-3 header-link text-black no-underline d-block border-bottom">Our Team</HashLink>
                                        <HashLink to="/#contact" scroll={(el) => { el.scrollIntoView({ block:'nearest', inline: "start"  }); setIsHeaderMblMenuOpen(!isHeaderMblMenuOpen)}} className="p-3 header-link text-black no-underline d-block border-bottom">Contact Us</HashLink>
                                    </SlidingPane>
                                </React.Fragment>
                            )
                        }
                    </Media>
                </Row>
            </Container>
        </header>
    )
}

export default Header