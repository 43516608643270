import React, { useEffect, useRef } from 'react';

const FadeInSection = (props:any) => {

    const [isVisible, setVisible] = React.useState(false);
    const domRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              setVisible(entry.isIntersecting);
            });
          });
      
          const { current } = domRef;
          //@ts-ignore
          observer.observe(current);
          //@ts-ignore
          return () => observer.unobserve(current);
    }, [])

    return (
        <div className={`fade-in-section ${isVisible ? "is-visible" : ""}`} ref={domRef}>
            {props.children}
        </div>
    )
}

export default FadeInSection