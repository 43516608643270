import React, { useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Media from "react-media";
import FadeInSection from "./FadeInSection";

const TabItem = (props: any) => {
    return (
        <Row className="py-md-4 my-4 align-items-center px-3">
            <Col md={6} xs={12} className="d-flex align-items-center justify-content-center order-md-1 order-2">
                <img src={props.tabImg} height="450" alt="solution" className="" />
            </Col>
            <Col md={6} xs={12} className=" order-md-2 order-1">
                <ul className="data-list">
                    {props.contentLine1 && (
                        <li className="mb-4 pl-3 px-4">{props.contentLine1}</li>
                    )}
                    {props.contentLine2 && (
                        <li className="mb-4 pl-3 px-4">{props.contentLine2}</li>
                    )}
                    {props.contentLine3 && (
                        <li className="mb-4 pl-3 px-4">{props.contentLine3}</li>
                    )}
                </ul>
                <Media query="(min-width: 768px)">
                    {matches => 
                        matches ? (
                            <a href={props.playLink} target="_blank" className="d-inline-block" rel="noreferrer">
                                <img src="images/playstore.svg" width="220" alt="playstore" />
                            </a>
                        ) :  <React.Fragment></React.Fragment>
                    }
                </Media>
            </Col>
            <Media query="(max-width: 767px)">
                {matches => 
                    matches ? (
                        <div className="text-center order-3 d-inline-block w-100 mt-3">
                            <a href={props.playLink} target="_blank" className="d-inline-block" rel="noreferrer">
                                <img src="images/playstore.svg" width="220" alt="playstore" />
                            </a>
                        </div>
                    ) :  <React.Fragment></React.Fragment>
                }
            </Media>
        </Row>
    )
}

const Solution = () => {

    const [isShown, setIsShown] = useState(true);
    
    const solutionList = ["farmer", "companies", "retailer"];
    
    const [arrayIndex, setArrayIndex] = useState(0);
    const [activeTab, setActiveTab] = useState(solutionList[0]);

    const handlePreviousSlide = (event:any) => {
       
        if(arrayIndex <= 2 && arrayIndex > 0) {
            let b = arrayIndex - 1;
            setArrayIndex(arrayIndex - 1);
            setActiveTab(solutionList[b]);
        } else {
            setArrayIndex(0);
        }
    }

    const handleNextSlide = (event:any) => {

        if(arrayIndex <= 2 && arrayIndex > -1) {
            let a = arrayIndex + 1;
            setArrayIndex(arrayIndex + 1);
            setActiveTab(solutionList[a]);
        } else {
            setArrayIndex(0);
        }
    }

    const handleTabClick = (k:any) => {
        setActiveTab(k);
        setArrayIndex(solutionList.indexOf(k));
    }



    return (
        <div id="solution" className="mb-5">
            <Container className="text-black">
                <div className="component-title mb-2 text-center text-uppercase font-weight-bold">SOLUTION</div>
                <div className="component-desc text-center text-black font-weight-bold mb-3">How We Make The Cycle <br /> Seamless</div>

                {isShown && (
                    <FadeInSection>
                        <div className="position-relative">
                                <Button variant="default" className="no-outline position-absolute slide-btn slide-left" onClick={handlePreviousSlide}>
                                    <img src="images/left.svg" alt="left" />
                                </Button>
                                <Button variant="default" className="no-outline position-absolute slide-btn slide-right" onClick={handleNextSlide}>
                                    <img src="images/right.svg" alt="right" />
                                </Button>
                        

                            <Media query="(min-width: 768px)">
                                {matches => 
                                    matches ? (
                                        <Tabs activeKey={activeTab} transition={false} id="solution" onSelect={handleTabClick} className="border-0 d-flex solution-list mt-4 pt-2">
                                            <Tab eventKey="farmer" title="Farmer" tabClassName="col text-center mr-4">
                                                <TabItem tabImg="images/farmer-app.png" tabName="Farmer" playLink="https://play.google.com/store/apps/details?id=com.farmer.khetipoint"
                                                    contentLine1="Get quick answers to all crop-related problems from verified agriculture experts"
                                                    contentLine2="Compare and buy agri-inputs at the best prices and get doorstep delivery in less than 24 hours"
                                                    contentLine3="Keep track of all your expenses in one place" />
                                            </Tab>
                                            <Tab eventKey="companies" title="Companies" tabClassName="col text-center mr-4">
                                                <TabItem tabImg="images/expert-app.png" tabName="Expert" playLink="https://play.google.com/store/apps/details?id=com.expert.khetipoint"
                                                    contentLine1="Reach farmers digitally to solve their crop-related problems"
                                                    contentLine2="Sell instantly to a large number of retailers"
                                                    contentLine3="" />
                                            </Tab>
                                            <Tab eventKey="retailer" title="Retailer" tabClassName="col text-center mr-4">
                                                <TabItem tabImg="images/dealer-app.png" tabName="Retailer" playLink="https://play.google.com/store/apps/details?id=com.delar.khetipoint"
                                                    contentLine1="Increase your customer reach by listing your shop on the KhetiPoint retailer application"
                                                    contentLine2="Make custom discount/combo offers to increase your sales"
                                                    contentLine3="Buy the best quality input at a very reasonable price" />
                                            </Tab>
                                        </Tabs>
                                    ) : (
                                        <React.Fragment>
                                            <div>
                                                <h3 className="text-green-light mb-0 font-weight-bold">Farmer</h3>
                                                <TabItem tabImg="images/farmer-app.png" tabName="Farmer" playLink="https://play.google.com/store/apps/details?id=com.farmer.khetipoint"
                                                    contentLine1="Get quick answers to all crop-related problems from verified agriculture experts"
                                                    contentLine2="Compare and buy agri-inputs at the best prices and get doorstep delivery in less than 24 hours"
                                                    contentLine3="Keep track of all your expenses in one place" />
                                            </div>
                                            <div>
                                                <h3 className="text-green-light mb-0 font-weight-bold">Companies</h3>
                                            <TabItem tabImg="images/expert-app.png" tabName="Expert" playLink="https://play.google.com/store/apps/details?id=com.expert.khetipoint"
                                                    contentLine1="Reach farmers digitally to solve their crop-related problems"
                                                    contentLine2="Sell instantly to a large number of retailers"
                                                    contentLine3="" />
                                            </div>
                                            <div>
                                                <h3 className="text-green-light mb-0 font-weight-bold">Retailer</h3>
                                                <TabItem tabImg="images/dealer-app.png" tabName="Retailer" playLink="https://play.google.com/store/apps/details?id=com.delar.khetipoint"
                                                    contentLine1="Increase your customer reach by listing your shop on the KhetiPoint retailer application"
                                                    contentLine2="Make custom discount/combo offers to increase your sales"
                                                    contentLine3="Buy the best quality input at a very reasonable price" />
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </Media>
                        </div>
                    </FadeInSection>
                 )}
            </Container>
        </div>
    )
}

export default Solution