import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from './Footer';
import LogoHeader from './LogoHeader';

const OurJourney = () => {
    return (
        <div id="journey">
            <LogoHeader />
            <Container className="text-black py-4">
                <div className="component-title mb-2 text-center text-uppercase font-weight-bold">OUR JOURNEY</div>
                <div className="mx-md-5 mx-4 mb-5">
                    <Row className="mt-5 position-relative dashed-border">
                        <Col md={6} xs={12} className=" mt-md-5">
                            <div className="mx-lg-5 mx-2">
                                <h3 className="text-black mb-md-5 mb-3 font-weight-bold">December 2020</h3>
                                <h5 className="text-black mb-md-0 mb-5">Launch Farmer App And MVP Of Expert App</h5>
                            </div>
                        </Col>
                        <Col md={6} xs={12} className="text-md-center">
                            <img src="images/home-page.png" height="450" alt="farmer app" className="" />
                        </Col>
                   </Row>
                    <Row className="position-relative dashed-border">
                        <Col md={6} xs={12} className="text-md-center order-md-1 order-2">
                            <img src="images/expert.png" height="450" alt="expert" className="" />
                        </Col>
                        <Col md={6} xs={12} className="order-md-2 order-1">
                            <div className="mt-5 mx-lg-5 mx-2">
                                <h3 className="text-black mb-md-5 mb-3 font-weight-bold">February 2021</h3>
                                <h5 className="text-black mb-md-0 mb-5">Expert App Launch</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row className="position-relative dashed-border">
                        <Col md={6} xs={12} className="">
                            <div className="mt-5 mx-lg-5 mx-2">
                                <h3 className="text-black mb-md-5 mb-3 font-weight-bold">August 2021</h3>
                                <h5 className="text-black mb-md-0 mb-5">Launch E-Commerce And The Polit Gave Amazing Results. Gross GMV Crossed 10 Lakh In Just Single Taluka.</h5>
                            </div>
                        </Col>
                        <Col md={6} xs={12} className="text-md-center">
                            <img src="images/e-commerce.png" height="450" alt="e-commerce" className="" />
                        </Col>
                    </Row>
                    <Row className="position-relative dashed-border">
                        <Col md={6} xs={12} className="text-md-center order-md-1 order-2">
                            <img src="images/kp.png" height="350" alt="kp" className="kp-img" />
                        </Col>
                        <Col md={6} xs={12} className="order-md-2 order-1">
                            <div className="mt-5 mx-lg-5 mx-2">
                                <h3 className="text-black mb-md-5 mb-3 font-weight-bold">November 2021</h3>
                                <h5 className="text-black mb-md-0 mb-5">Expanded In 8+ Sub Diswtrict</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row className="position-relative dashed-border last-filled-circle">
                        <Col md={6} xs={12}>
                            <div className="pt-5 mx-lg-5 mx-2 px-lg-5">
                                <h3 className="text-black my-5 font-weight-bolder">This Is Just The Beginning</h3>
                            </div>
                        </Col>
                    </Row>   
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default OurJourney