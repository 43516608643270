import { useState } from 'react';
import { Container } from 'react-bootstrap';
import FadeInSection from './FadeInSection';
import TestimonialsVideo from './TestimonialsVideo';

const Testimonials = (props:any) => {

    const testimonialObj = [{
            'thumbnail' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F1.jpg?alt=media&token=9519e67e-843c-4a23-bee3-7c3d42919cfe',
            'upperClasses' : 'col-md-4 col-6 px-0',
            'mainClassess' : 'large-thumb thumb-radius h-auto position-relative',
            'imgClassess' : 'w-100 h-100',
            'video' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F1.mp4?alt=media&token=78905ecc-decf-488d-8fa9-53473fa9b22f',
            'video-min' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F1-min.mp4?alt=media&token=fbe2a2a3-2ea6-4f54-b32d-21e50284417b'
        },
        {
            'thumbnail' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F2.jpg?alt=media&token=add4fdff-cf00-4ba1-9a87-11e8f0168e73',
            'upperClasses' : 'position-absolute center-testi-section shadow px-0',
            'mainClassess' : 'small-thumb thumb-radius h-auto position-relative',
            'imgClassess' : 'w-100 h-100',
            'video' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F2.mp4?alt=media&token=d596e65e-8338-422a-a329-0e53bb7cb9f0',
            'video-min' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F2-min.mp4?alt=media&token=173f0119-5e31-464f-ae37-a1d21e7f701f'
        },
        {
            'thumbnail' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F4.jpg?alt=media&token=78ccdd01-36fa-4e8d-8ce2-5d507636688a',
            'upperClasses' : 'ml-auto mt-5 right-thmb-wrapper',
            'mainClassess' : 'large-thumb thumb-radius h-auto position-relative',
            'imgClassess' : 'w-100 h-100',
            'video' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F4.mp4?alt=media&token=bbfecd34-a083-4503-872d-1eb412a50d76',
            'video-min' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F4-min.mp4?alt=media&token=7e70730a-bc7a-4c29-b20b-bcabaff2a1b9'
        },
        {
            'thumbnail' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F7.jpg?alt=media&token=7f0726a7-b182-4fdd-b498-4648bda248a4',
            'upperClasses' : 'd-flex justify-content-center w-100 my-md-0 my-5',
            'mainClassess' : 'large-thumb thumb-radius h-auto position-relative',
            'imgClassess' : 'w-100 center-thumb',
            'video' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F7.mp4?alt=media&token=ddd6670d-d772-4e23-a1b2-31a1d1e0181e',
            'video-min' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F7-min.mp4?alt=media&token=0e981cc5-c479-403f-90d2-bdf4dcea471b'
        },
        {
            'thumbnail' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F5.jpg?alt=media&token=e4dbad5e-b2f0-4c77-9f5b-feb8a090b28b',
            'upperClasses' : 'col-6 ml-md-5 pl-md-5 mt-5',
            'mainClassess' : 'thumb-radius h-auto position-relative ml-md-5',
            'imgClassess' : 'w-100 h-100',
            'video' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F5.mp4?alt=media&token=6629b585-713e-41b2-8642-06bd53157a81',
            'video-min' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F5-min.mp4?alt=media&token=bb75b756-eb34-49ad-869d-0a014ea70161'
        },
        {
            'thumbnail' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F6.jpg?alt=media&token=6b279e8b-1de6-4163-9d26-8923d98c3efe',
            'upperClasses' : 'col-md-4 col-6 ml-auto',
            'mainClassess' : 'top-thumb-m thumb-radius h-auto position-relative',
            'imgClassess' : 'w-100 h-100',
            'video' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F6.mp4?alt=media&token=d89d8396-70bf-473f-a7b0-f54507bc897f',
            'video-min' : 'https://firebasestorage.googleapis.com/v0/b/kheti-point-2020.appspot.com/o/website-content%2F6-min.mp4?alt=media&token=ef2514e0-73e3-4c75-8f5c-e964cac21776'
        }
        
    ]


    const [isShown, setIsShown] = useState(true);
    return(
        <div id="testimonial" className="mb-5 pt-3">
            <Container className="py-5">

                <div className="component-title pb-5 text-center text-uppercase font-weight-bold text-gray-dark">Testimonials</div>
                {isShown && (
                    <FadeInSection>
                        <div className="mt-5 position-relative d-flex flex-wrap">
                            {testimonialObj.map((thumbItem, index) => {
                                return (
                                
                                        <TestimonialsVideo key={index} upperClasses={thumbItem.upperClasses} mainClassess={thumbItem.mainClassess} thumbnail={thumbItem.thumbnail} imgClassess={thumbItem.imgClassess} video={thumbItem.video} videoMin={thumbItem['video-min']} />
                                
                                )
                            })}
                        </div>
                    </FadeInSection>
                 )}
             
            </Container>
        </div>
    )
}

export default Testimonials