import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import Landing from './component/Landing';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TermsAndConditions from './component/TermsAndConditions';
import OurJourney from './component/OurJourney';
import { useFirebase } from './server/firebase.config';

function App() {

  useFirebase()
  
  return (
    <div className="h-100">
      <BrowserRouter>
        <Switch>
          <Route path="/terms-policy">
            <TermsAndConditions />
          </Route>
          <Route path="/our-journey">
            <OurJourney />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>

      </BrowserRouter>
    </div>
  );
}

export default App;
