import { Col, Container, Row } from 'react-bootstrap'

const Footer = () => {
    return (
        <footer className="bg-dark text-white py-5">
            <Container>
                <Row>
                    <Col md={4} xs={12} className="d-flex flex-column mb-md-0 mb-3">
                        <h5 className="font-weight-bold mb-3">General</h5>
                        {/* <a className="text-white mb-1 no-underline" href="/our-journey">Our Journey</a> */}
                        <a className="text-white mb-1 no-underline" href="/terms-policy">Terms & Policies</a>
                    </Col>
                    <Col md={4} xs={12} className="mb-md-0 mb-3">
                        <h5 className="font-weight-bold mb-3">Address</h5>
                        <address>
                            903, B-Square II, Iskon<br/>
                            Ambli Road, Ahmedabad,<br/>
                            Gujarat - 380015
                        </address>
                    </Col>
                    <Col md={4} xs={12} className="mb-md-0 mb-3">
                        <h5 className="font-weight-bold mb-3">Connect to our social media</h5>
                        <div>
                            <a href="https://www.facebook.com/Khetipoint" className="mr-3 no-underline" target="_blank" rel="noreferrer">
                                <img src="images/facebook.svg" width="32" alt="facebook" className="" />  
                            </a>
                            <a href="https://www.instagram.com/kheti_point/" className="mr-3 no-underline" target="_blank" rel="noreferrer">
                                <img src="images/instagram.svg" width="32" alt="instagram" className="" />  
                            </a>
                            <a href="https://www.linkedin.com/company/khetipoint" className="mr-3 no-underline" target="_blank" rel="noreferrer">
                                <img src="images/linkedin.svg" width="32" alt="instagram" className="" />  
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer