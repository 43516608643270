import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FadeInSection from "./FadeInSection";

const TestimonialsVideo = (props: any) => {

    const [show, setShow] = useState(false);
    
    const handleVideoModalClose = () => setShow(false);
    const handleVideoModalShow = () => setShow(true);

    const [isShown, setIsShown] = useState(true);


    return (
        <React.Fragment>
            <div className={props.upperClasses}>

            {isShown && (
                <FadeInSection>
                    <div className={props.mainClassess} onClick={handleVideoModalShow}>
                        <img src={props.thumbnail} alt="thumbnail" className={props.imgClassess} />
                        <Button variant="link" className="px-0 no-outline video-play-btn position-absolute">
                            <img src="images/play-icon.svg" width="80" alt="play" className="" />
                        </Button>
                    </div>
                </FadeInSection>
            )}


            </div>

            <Modal size="lg" show={show} onHide={handleVideoModalClose} animation={false} centered>
                <Modal.Body className="p-0 position-relative video-modal-body">
                    <video loop={false} autoPlay={true} controls className="rounded w-100">
                        <source src={props.videoMin} />
                    </video>
                    <Button variant="default" className="bg-white rounded-0 no-outline position-absolute video-close" onClick={handleVideoModalClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </Button>
                </Modal.Body>
                
            </Modal>
        </React.Fragment>

    )
}

export default TestimonialsVideo