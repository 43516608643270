import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import FadeInSection from './FadeInSection';

const Team = () => {

    const [isShown, setIsShown] = useState(true);

    return (
        <div id="team" className="py-4">
            <Container className="pb-3 text-black">
                <div className="component-title mb-2 text-center text-uppercase font-weight-bold">Our Team</div>
                {isShown && (
                    <FadeInSection>
                        <Row className="mt-4">
                            <div className="mr-lg-4 mr-md-2 p-3 text-center team-wrapper mb-md-0 mb-4">
                                <div className="rounded position-relative team--info">
                                    <img src="images/vishal.jpg" alt="user" className="m-0 rounded-circle border team-user-img" /> 
                                </div>
                                <div className="mt-4 member--name">
                                    <div className="font-weight-bold text-black mb-2 h4">Vishal Domadia</div>
                                    <div className="font-weight-normal text-black h5">Co-founder</div>
                                </div>
                                <div className="member-info mt-4">
                                    <a href="https://www.linkedin.com/in/vishal-domadia-47361617/" className="mr-3" target="_blank" rel="noreferrer">
                                        <img src="images/linkedin.svg" width="35" alt="linkedin" className="" />  
                                    </a>
                                    {/* <a href="https://www.facebook.com/Khetipoint" className="mr-3" target="_blank" rel="noreferrer">
                                        <img src="images/facebook.svg" width="35" alt="facebook" className="" />  
                                    </a>
                                    <a href="https://www.instagram.com/kheti_point/" className="mr-3" target="_blank" rel="noreferrer">
                                        <img src="images/instagram.svg" width="35" alt="instagram" className="" />  
                                    </a> */}
                                </div> 
                            </div>
                            <div className="mr-lg-4 mr-md-2 p-3 text-center team-wrapper">
                                <div className="text-center rounded position-relative team--info">
                                    <img src="images/khemraj.jpeg" alt="user" className="m-0 rounded-circle border team-user-img" />    
                                </div>
                                <div className="mt-4 member--name">
                                    <div className="font-weight-bold text-black mb-2 h4">Khem Raj Meena</div>
                                    <div className="font-weight-normal text-black h5">Co-founder</div>
                                </div>
                                <div className="member-info mt-4">
                                    <a href="https://www.linkedin.com/in/khemrajiitk/" className="mr-3" target="_blank" rel="noreferrer">
                                        <img src="images/linkedin.svg" width="35" alt="instagram" className="" />  
                                    </a>
                                    <a href="https://www.instagram.com/breaking_photon/" className="mr-3" target="_blank" rel="noreferrer">
                                        <img src="images/instagram.svg" width="35" alt="instagram" className="" />  
                                    </a>
                                    {/* <a href="https://www.facebook.com/Khetipoint" className="mr-3" target="_blank" rel="noreferrer">
                                        <img src="images/facebook.svg" width="35" alt="facebook" className="" />  
                                    </a> */}
                                </div>
                            </div>
                            <div className="mr-lg-4 mr-md-2 p-3 text-center team-wrapper mb-md-0 mb-4">
                                <div className="text-center rounded position-relative team--info">
                                    <img src="images/darshit.jpg" alt="user" className="m-0 rounded-circle border team-user-img" />  
                                </div>
                                <div className="mt-4 member--name">
                                    <div className="font-weight-bold text-black mb-2 h4">Darshit Talavia</div>
                                    <div className="font-weight-normal text-black h5">Co-founder</div>
                                </div>
                                <div className="member-info mt-4">
                                        {/* <a href="https://www.facebook.com/Khetipoint" className="mr-3" target="_blank" rel="noreferrer">
                                            <img src="images/facebook.svg" width="35" alt="facebook" className="" />  
                                        </a>
                                        <a href="https://www.instagram.com/kheti_point/" className="mr-3" target="_blank" rel="noreferrer">
                                            <img src="images/instagram.svg" width="35" alt="instagram" className="" />  
                                        </a> */}
                                        <a href="https://www.linkedin.com/in/darshit-talavia-ba5a9b141/" className="mr-3" target="_blank" rel="noreferrer">
                                            <img src="images/linkedin.svg" width="35" alt="linkedin" className="" />  
                                        </a>
                                    </div>
                            </div>
                        </Row>
                    </FadeInSection>
                 )}
            </Container>
        </div>
    )
}

export default Team