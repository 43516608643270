import firebase from "firebase/app";
import "firebase/analytics";
import { useEffect } from "react";

export const firebaseConfig = {
    apiKey: "AIzaSyDXCkg6MC85dhMDnO6sHE0mDe4NZzF3ySw",
    authDomain: "kheti-point-2020.firebaseapp.com",
    databaseURL: "https://kheti-point-2020.firebaseio.com",
    projectId: "kheti-point-2020",
    storageBucket: "kheti-point-2020.appspot.com",
    messagingSenderId: "1049286782102",
    appId: "1:1049286782102:web:7aab5947cef3dd7e0266b7",
    measurementId: "G-8QG346WNVP"
};

export const useFirebase = () => {
    useEffect(() => {
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
    }, [])
}