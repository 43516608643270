import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactUs from './ContactUs';
import FadeInSection from './FadeInSection';
import Footer from './Footer';
import Header from './Header';
import './Landing.css';
import Solution from './Solution';
import Team from './Team';
import Testimonials from './Testimonials';
import Vision from './Vision';

const Landing = () => {

    const [isShown, setIsShown] = useState(true);

    return (
        <div className="bg-white">
            <Header />
            <div className="bg-dark py-5 d-flex align-items-center position-fixed w-100 front-view">
                <Container>
                    <Row>
                        <Col lg={6} xs={12} className="banner pt-lg-0 pt-5">
                            <h1 className="font-weight-light heading-title mr-lg-5">Advisory cum hyperlocal market place that let's farmers compare and buy agri-inputs at best price</h1>
                            <div className="text-lg-left text-right">
                                <a href="https://play.google.com/store/apps/details?id=com.farmer.khetipoint" target="_blank" className="mt-3 d-inline-block" rel="noreferrer">
                                    <img src="images/playstore.svg" width="280" alt="facebook" className="" />
                                </a>
                            </div>
                        </Col>
                        <Col lg={6} xs={12}>
                            <div className="top-agro--wrapper">
                                <img src="images/KPmain.svg" height="320" alt="main" className="position-relative" />
                            </div>
                            <div className="top-agro--wrapper">
                                <img src="images/Gradient.svg" height="348" alt="Gradient" className="gradient-img" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-white after-section">
                <Vision />
                <Solution />
                <Testimonials />
                <Team />
                <ContactUs />
                <Footer />
            </div>
        </div>
    )
}


export default Landing