import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Media from 'react-media';

export const OnlyLogo = () => {
    return (
        <Media query="(min-width: 768px)"> 
            {matches => 
                matches ? (
                    <a href="/">
                        <img src="images/logo.png" width="300" alt="logo" className="" />
                    </a>
                ) : (
                    <a href="/">
                        <img src="images/logo.png" width="200" alt="logo" className="" />
                    </a>
                )
            }
        </Media> 
    )
}

const LogoHeader = () => {
    return (
        <header className="bg-white py-3 sticky-top box-shadow border-bottom main-header">
            <Container>
                <Row className="m-0">
                   <OnlyLogo />
                </Row>
            </Container>
        </header>
    )
}

export default LogoHeader